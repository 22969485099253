import PropTypes from "prop-types";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setFilterExtendedStatsDomains,
  fetchExtendedStatsDomains,
  toogleDataCheck,
} from "../../store/app/DataQualityStatus/DataQualitySlice";
import {
  MultiSelectTree,
  getMultiSelectTreeValue,
} from "@progress/kendo-react-dropdowns";
import {
  processMultiSelectTreeData,
  expandedState,
  fields,
  evaluateSelectedValues,
  onChangeTreeData,
} from "../kendoComponents/multiSelectTreeUtils/shared-dd-multiselecttree-data-operations";
import { useEffectOnChange } from "../hooks/CustomHooks";

const ExtendedStatsSelector = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const refreshCount = useSelector(
    (state) => state.dataQualityReducer.refreshCount
  );

  const [value, setValue] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [selected, setSelected] = useState(0);
  const [isReady, setIsReady] = useState(false);

  const extendedStatsDomains = useSelector(
    (state) => state.dataQualityReducer.extendedStatsDomains
  );

  const stateValue = useSelector(
    (state) => state.dataQualityReducer.filterExtendedStatsDomains
  );

  useEffect(() => {
    setTreeData(
      processMultiSelectTreeData(transformData(extendedStatsDomains), {
        expanded,
        value,
        ...fields,
      })
    );
  }, [expanded, value, extendedStatsDomains]);

  useEffect(() => {
    dispatch(fetchExtendedStatsDomains());
  }, [dispatch]);

  useEffect(() => {
    // get parameters from url query

    let value;
    try {
      value = JSON.parse(searchParams.get("extendedStatsDomains")) || [];
    } catch (error) {
      value = props.defaultValue || [];
      console.error(error);
    }

    dispatch(setFilterExtendedStatsDomains(value));
  }, [navigate, refreshCount]);

  useEffectOnChange(() => {
    let count = 0;

    value.forEach((item) => {
      if (typeof item.id === "number") {
        count++;
      }
    });
    setSelected(value.length - count);
  }, [value]);

  useEffectOnChange(() => {
    if (treeData.length > 0 && !isReady) {
      let newStateValue = [];
      stateValue.forEach((item) => {
        if (typeof item == "number") {
          const category = treeData.find(category => category.id === item);
          if (category) {
              category.items.forEach(subItem => {
                  newStateValue.push(subItem.id);
              });
          }   
      }});

      newStateValue = [...new Set([...stateValue, ...newStateValue])] ;
      dispatch(setFilterExtendedStatsDomains(newStateValue));
      console.log("stateValue", stateValue  );
      console.log("newStateValue", newStateValue  );

      let valueFilter = onChangeTreeData(treeData, newStateValue);
      
      setValue(valueFilter);
      setIsReady(true);
    }
  }, [treeData, isReady]);

  const onChangeTree = (event) => {
    dispatch(toogleDataCheck(true));
     

    if (event.operation == "clear") {
      dispatch(setFilterExtendedStatsDomains([]));
    }

    if (event.operation == "toggle") {
      const { checkedValues, uncheckedValues } = evaluateSelectedValues(
        event.items
      );

      let newStateValue = [...stateValue];

      uncheckedValues.forEach((id) => {
        const index = newStateValue.indexOf(id);
        if (index > -1) {
          newStateValue.splice(index, 1);
        }
      });

      checkedValues.forEach((id) => {
        if (!newStateValue.includes(id)) {
          newStateValue.push(id);
        }
      });

      dispatch(setFilterExtendedStatsDomains(newStateValue));
    }

    setValue(
      getMultiSelectTreeValue(transformData(extendedStatsDomains), {
        ...fields,
        ...event,
        value,
      })
    );
  };

  const onExpandChange = useCallback(
    (event) => setExpanded(expandedState(event.item, fields.dataItemKey, expanded)),
    [expanded]
  );

  const transformData = (inputArray) => {
    // Group items by their `status_name` and `status_id` properties
    const grouped = inputArray.reduce((acc, item) => {
      const parentText = item.status_name;
      const parentId = item.status_id;

      if (!parentText || parentId === undefined) return acc; // Skip items with missing status_name or status_id

      // Find or create the parent entry
      let parent = acc.find(
        (group) => group.text === parentText && group.id === parentId
      );
      if (!parent) {
        parent = { text: parentText, id: parentId, items: [] };
        acc.push(parent);
      }

      // Add the current item as a child
      if (item.id && item.name) {
        parent.items.push({ text: item.name, id: item.id });
      }

      return acc;
    }, []);

    console.log("grouped", grouped);
    return grouped;
  };


  return (
    <MultiSelectTree
      id="multiSelectTreeExtendedStats"
      data={treeData}
      value={value}
      onChange={onChangeTree}
      textField={fields.textField}
      dataItemKey={fields.dataItemKey}
      checkField={fields.checkField}
      checkIndeterminateField={fields.checkIndeterminateField}
      expandField={fields.expandField}
      subItemsField={fields.subItemsField}
      onExpandChange={onExpandChange}
      adaptive={true}
      tags={
        selected > 0
          ? [
              {
                text:
                  selected == 1
                    ? `${selected} stato selezionato`
                    : `${selected} stati selezionati`,
                data: [...value],
              },
            ]
            : [ {
              text: `Nessuno Stato Selezionato`,
              data: [...value],
            }]
      }
    />
  );
};

ExtendedStatsSelector.propTypes = {
  defaultValue: PropTypes.array,
};

export default ExtendedStatsSelector;
