import { filterBy } from "@progress/kendo-react-data-tools";
import { getter, setter } from "@progress/kendo-react-common";



const mapMultiSelectTreeData = (data, options) => {
  const {
    keyGetter,
    subItemGetter,
    subItemSetter,
    checkSetter,
    expandedSetter,
    checkIndeterminateSetter,
    valueMap,
    expandedMap,
  } = options;
  if (!data || !data.length) {
    return [data, false];
  }
  let hasChecked = false;
  const newData = [...data].map((dataItem) => {
    const [children, hasCheckedChildren] = mapMultiSelectTreeData(
      subItemGetter(dataItem),
      options
    );
    const isChecked = valueMap[keyGetter(dataItem)];
    if (isChecked || hasCheckedChildren) {
      hasChecked = true;
    }
    const newItem = {
      ...dataItem,
    };
    expandedSetter(newItem, expandedMap[keyGetter(newItem)]);
    subItemSetter(newItem, children);
    checkSetter(newItem, isChecked);
    checkIndeterminateSetter(newItem, !isChecked && hasCheckedChildren);

    return newItem;
  });

  return [newData, hasChecked];
};



export const dataItemKey = "id";
export const checkField = "checkField";
export const checkIndeterminateField = "checkIndeterminateField";
export const subItemsField = "items";
export const expandField = "expanded";
export const textField = "text";

export const fields = {
  dataItemKey,
  checkField,
  checkIndeterminateField,
  expandField,
  subItemsField,
};

export const evaluateSelectedValues = (items) => {
  let checkedValues = [];
  let uncheckedValues = [];

  items.forEach((item) => {
    if (item.items?.length > 0) {
      item.items.forEach((subItem) => {
        if (!subItem.checkField || subItem.checkField == undefined) {
          checkedValues.push(subItem.id);
        } else {
          uncheckedValues.push(subItem.id);
        }
      });
    } else if (!item.checkField || item.checkField == undefined) {
      checkedValues.push(item.id);
    } else {
      uncheckedValues.push(item.id);
    }
  });

  return { checkedValues, uncheckedValues };
};
export const getValueMap = (value, idGetter) => {
  const map = {};
  if (value && value.length) {
    value.forEach((item) => {
      map[idGetter(item)] = true;
    });
  }

  return map;
};
export const expandedState = (item, dataItemKey, expanded) => {
  const nextExpanded = expanded.slice();
  const keyGetter = getter(dataItemKey);
  const itemKey = keyGetter(item);
  const index = expanded.findIndex((currentKey) => {
    return currentKey === itemKey;
  });
  index === -1 ? nextExpanded.push(itemKey) : nextExpanded.splice(index, 1);

  return nextExpanded;
};
export const processMultiSelectTreeData = (tree, options) => {
  const {
    subItemsField = "items",
    checkField = "checkField",
    checkIndeterminateField = "checkIndeterminateField",
    expandField = "expanded",
    dataItemKey,
    value,
    filter,
    expanded,
  } = options;
  const keyGetter = getter(dataItemKey);
  const filtering = Boolean(filter && filter.value);
  const expandedMap = {};
  expanded.forEach((id) => (expandedMap[id] = true));
  const [result] = mapMultiSelectTreeData(tree, {
    valueMap: getValueMap(value, keyGetter),
    expandedMap: expandedMap,
    keyGetter,
    expandedSetter: setter(expandField),
    subItemGetter: getter(subItemsField),
    subItemSetter: setter(subItemsField),
    checkSetter: setter(checkField),
    checkIndeterminateSetter: setter(checkIndeterminateField),
  });

  return filtering ? filterBy(result, [filter], subItemsField) : result;
};

export const onChangeTreeData = (treeData, stateValue) => {
  let valueFilter = [];

  treeData.forEach((item) => {
    if (stateValue.includes(item.id)) {
      valueFilter.push({ text: item.text, id: item.id });
    }

    item.items.forEach((child) => {
      if (stateValue.includes(child.id)) {
        valueFilter.push({ text: child.text, id: child.id });
      }
    });

    const allChildIdsInStateValue = item.items.every((child) =>
      stateValue.includes(child.id)
    );
    if (allChildIdsInStateValue) {
      valueFilter.push({ text: item.text, id: item.id });
    }
  });

  return valueFilter;
}
