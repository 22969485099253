import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_OVERX_URL}/api/v1/sicurezza/`;

const login = (username, password) => {
  return axios
    .post(`${API_URL}login`, {
      username,
      password,
    })
    .then((response) => {
      if (response.data.access_token) {
        localStorage.setItem("userInfo", JSON.stringify(response.data));
      }
      return response.data;
    })
    .catch((e) => {
      console.log("Err on loginAuth ", e);
      return e;
    });
};

const logout =  async () => {
  const refreshToken = JSON.parse(
    localStorage.getItem("userInfo")
  ).refresh_token;

  return axios
    .post(`${API_URL}logout`, {
      refresh_token: refreshToken,
    })
    .then(() => {
      window.location = "/home";
    })
    .catch((error) => {
      throw error;
    })
    .finally(() => {
      localStorage.removeItem("userInfo");
      localStorage.removeItem("requestIDs");
      localStorage.removeItem("lastKnownPath");
      localStorage.removeItem("lastSearchValues");
      localStorage.removeItem("redirectUri");
      localStorage.setItem("message", "");
    });
};

const getCurrentTokenUser = () => {
  const user = JSON.parse(localStorage.getItem("userInfo"));
  if (user && user.accessToken) {
    return JSON.parse(localStorage.getItem("userInfo"));
  }
  return JSON.parse('{"user":"nouser"}');
};

const refreshToken = async () => {
  const refreshTokenOld = JSON.parse(
    localStorage.getItem("userInfo")
  ).refresh_token;
  return axios
    .post(`${API_URL}refresh-token`, { refresh_token: refreshTokenOld })
    .then((response) => {
      if (response.data.access_token) {
        localStorage.setItem("userInfo", JSON.stringify(response.data));
      }

      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const AuthService = {
  login,
  logout,
  getCurrentTokenUser,
  refreshToken,
};

export default AuthService;
