import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Label, Form, Button } from "reactstrap";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { ColumnMenu } from "../components/kendoComponents/columnMenu/ColumnMenu";
import { GridPager } from "../components/kendoComponents/gridPager/GridPager";
import { ColumnProps } from "../components/kendoComponents/columnProps/ColumnProps";
import { useNavigate } from "react-router-dom";
import LoaderSpin from "../components/loaderSpin/LoaderSpin";
import ModalManualValidation from "../components/manualValidation/ModalManualValidation";
import "../assets/scss/kendo-theme-custom.scss";
import {
  fetchVersionList,
  setCallWithUser,
} from "../store/app/DataRollCall/DataRollCallSlice";

import ProductTypeSelector from "../components/DataQuality/ProductTypeSelector";
import DomainsSelector from "../components/DataQuality/DomainsSelector";
import DataProductTreeSelector from "../components/DataQuality/DataProductTreeSelector";
import RollCallStatusDomainsSelector from "../components/DataQuality/RollCallStatusDomainsSelector";
import FilterSubmit from "../components/DataQuality/FilterSubmit";
import FilterOverlay from "../components/filterOverlay/FilterOverlay";
import DateCell from "../components/kendoComponents/dateCell/DateCell";
import { AtlanCell } from "../components/customRender/renders/Renders";


const VersionListRollCall = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const refreshCount = useSelector(
    (state) => state.dataQualityReducer.refreshCount
  );
  const searchParams = new URLSearchParams(location.search);
  const callWithUser = useSelector(
    (state) => state.dataRollCallReducer.callWithUser
  );

  const isSpinnerVisible = useSelector(
    (state) => state.dataQualityReducer.loading
  );
  const dataCheck = useSelector((state) => state.dataQualityReducer.dataCheck); 

  const versionList = useSelector(
    (state) => state.dataRollCallReducer.versionList || []
  );

  const filterDomains = useSelector(
    (state) => state.dataQualityReducer.filterDomains
  );

  const filterStatusCount = useSelector(
    (state) => state.dataRollCallReducer.filterStatusCountDomains
  );
  const filterProductTypes = useSelector(
    (state) => state.dataRollCallReducer.filterProductTypes
  );
  const filterDataProducts = useSelector(
    (state) => state.dataQualityReducer.filterDataProducts
  );

  const [dataState, setDataState] = useState({
    sort: [
      {
        field: "code",
        dir: "asc",
      },
    ],
    skip: 0,
    take: 20,
  });

  const [dataResult, setDataResult] = useState(process(versionList, dataState));

  // Open modal window
  const toggleManualValidation = () => {
    setModal(!modal);
  };

  const [modal, setModal] = useState(false);

  useEffect(() => {
    dispatch(
      fetchVersionList(
        filterStatusCount,
        filterDomains,
        filterProductTypes,
        callWithUser,
        filterDataProducts
      )
    );
    dispatch(setCallWithUser(false));
  }, [refreshCount, navigate]);

  useEffect(() => {
    setDataResult(process(versionList, dataState));
  }, [versionList]);

  return (
    <>
      <Form>
        <Row className="bg-light-primary px-4 pt-2">
          <Col>
            <Row className="g-3">
              <Col>
                <div className="mb-1">
                  <Label for="categoryName">Data Products</Label>
                  <DataProductTreeSelector />
                </div>
              </Col>
              <Col>
                <div className="mb-2">
                  <Label for="categoryName">Tipo prodotto</Label>
                  <ProductTypeSelector />
                </div>
              </Col>
              <Col>
                <div className="mb-2">
                  <Label for="categoryName">Roll-call Status</Label>
                  <RollCallStatusDomainsSelector />
                </div>
              </Col>
              <Col md="auto" className="pt-1">
                <FilterSubmit color="primary" className="mt-3" text="Filtra" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      <Container fluid className="py-2 px-4  position-relative">
        <Row>
          <Col>
            <div className="grid_task_container border">
              <Grid
                style={{ height: "100%" }}
                pageable={true}
                sortable={true}
                total={dataResult?.length}
                data={dataResult}
                {...dataState}
                groupable={true}
                pager={(props) => GridPager(props, versionList)}
                onDataStateChange={(e) => {
                  setDataState(e.dataState);
                  setDataResult(process(versionList, e.dataState));
                }}
              >
                <Column
                  {...ColumnProps("versionId", dataState)}
                  title="ID versione"
                  columnMenu={ColumnMenu}
                  width={120}
                  cell={(props) => (
                    <td>
                      <a
                        style={{ cursor: "pointer" }}
                        className="fw-bold text-decoration-underline"
                        onClick={(event) => {
                          navigate(
                            "/versioni/detail/" +
                              props.dataItem.versionId +
                              "?" +
                              searchParams
                          );
                        }}
                       onKeyDown= {()=>console.log("onKeyDown")}
                      >
                        {props.dataItem.versionId}
                      </a>
                    </td>
                  )}
                />
                <Column
                  {...ColumnProps("productName", dataState)}
                  title="Product"
                  columnMenu={ColumnMenu}
                  cell={(props) => AtlanCell(props)}
                />
                <Column
                  {...ColumnProps("referenceDate", dataState)}
                  title="Data riferimento"
                  columnMenu={ColumnMenu}
                  cell={(props) => DateCell(props, false)}
                />
                <Column
                  {...ColumnProps("creationTs", dataState)}
                  title="Creazione versione"
                  columnMenu={ColumnMenu}
                  cell={(props) => DateCell(props, true)}
                />
                <Column
                  {...ColumnProps("extStatusName", dataState)}
                  title="Stato"
                  columnMenu={ColumnMenu}
                />
                <Column
                  {...ColumnProps("windowStartTs", dataState)}
                  title="Inizio finestra"
                  columnMenu={ColumnMenu}
                  cell={(props) => DateCell(props, true)}
                />
                <Column
                  {...ColumnProps("windowEndTs", dataState)}
                  title="Fine finestra"
                  columnMenu={ColumnMenu}
                  cell={(props) => DateCell(props, true)}
                />
                <Column
                  {...ColumnProps("rollCallStatusName", dataState)}
                  title="Stato roll-call"
                />
                <Column {...ColumnProps("rollCallSeverityName", dataState)} title="Severity" />
              </Grid>
            </div>
          </Col>
        </Row>
        <LoaderSpin isVisible={isSpinnerVisible} />
        <FilterOverlay isVisible={dataCheck}/>
      </Container>
      <ModalManualValidation
        modal={modal}
        toggleManualValidation={toggleManualValidation}
      />
    </>
  );
};

export default VersionListRollCall;
