import { useEffect, version } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams} from "react-router-dom";
import LineageDrawer from "../components/LineageDrawer/LineageDrawer";
import { fetchVersionLineage } from "../store/app/DataQualityStatus/DataQualitySlice";
import LoaderSpin from "../components/loaderSpin/LoaderSpin";


const VersionLineage = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { versionId } = useParams();
  


  const versionLineagePayload = useSelector((state) => state.dataQualityReducer.versionLineage );
  const isSpinnerVisible = useSelector((state) => state.dataQualityReducer.loading );


  const params = {
    nodeTooltipKeys: [
      { key: "version_id", displayKey: "Version ID" },
      { key: "domain_name", displayKey: "Domain Name" },
      { key: "class_name", displayKey: "Class Name" },
      { key: "product_name", displayKey: "Product Name" },
      { key: "reference_date", displayKey: "Reference Date" },
      { key: "status_name", displayKey: "Status Name" },
    ],
    lineTooltipKeys: [
      { key: "process_name", displayKey: "Process Name" },
      { key: "process_type_name", displayKey: "Process Type Name" },
      { key: "process_description", displayKey: "Process Description" },
      { key: "is_manual_validation", displayKey: "Is Manual Validation" },
      { key: "revision_number", displayKey: "Revision Number" },
      { key: "revision_release_date", displayKey: "Revision Release Date" },
      { key: "revision_release_notes", displayKey: "Revision Release Notes" },
    ],
    primaryKey: "version_id",
  };

  useEffect(() => {
    dispatch(fetchVersionLineage(versionId));    
  },[navigate]);


  return (
    <>
    <LoaderSpin isVisible={isSpinnerVisible} />
    <LineageDrawer data={versionLineagePayload} params={params} />
    </>
  );

};


export default VersionLineage;