import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Label, Form } from "reactstrap";
import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { ColumnMenu } from "../components/kendoComponents/columnMenu/ColumnMenu";
import { GridPager } from "../components/kendoComponents/gridPager/GridPager";
import { ColumnProps } from "../components/kendoComponents/columnProps/ColumnProps";
import { DateCell }  from "../components/kendoComponents/dateCell/DateCell"; 
import { AtlanCell } from "../components/customRender/renders/Renders";
import {
  fetchProductTypeList,
  setCallWithUser,
} from "../store/app/DataRollCall/DataRollCallSlice";

import { useNavigate } from "react-router-dom";
import LoaderSpin from "../components/loaderSpin/LoaderSpin";

import ModalManualValidation from "../components/manualValidation/ModalManualValidation";
import "../assets/scss/kendo-theme-custom.scss";

import DomainsSelector from "../components/DataQuality/DomainsSelector";
import DataProductTreeSelector from "../components/DataQuality/DataProductTreeSelector";
import RollCallStatusDomainsSelector from "../components/DataQuality/RollCallStatusDomainsSelector";
import FilterSubmit from "../components/DataQuality/FilterSubmit";
import FilterOverlay from "../components/filterOverlay/FilterOverlay";

const ProductTypeListRollCall = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const refreshCount = useSelector(
    (state) => state.dataQualityReducer.refreshCount
  );
  const searchParams = new URLSearchParams(location.search);
  const callWithUser = useSelector(
    (state) => state.dataRollCallReducer.callWithUser
  );

  const isSpinnerVisible = useSelector(
    (state) => state.dataQualityReducer.loading
  );
  const dataCheck = useSelector((state) => state.dataQualityReducer.dataCheck); 

  const productTypeList = useSelector(
    (state) => state.dataRollCallReducer.productTypeList || []
  );

  const filterDomains = useSelector(
    (state) => state.dataQualityReducer.filterDomains
  );
  const filterDataProducts = useSelector(
    (state) => state.dataQualityReducer.filterDataProducts
  );
  const filterStatusCount = useSelector(
    (state) => state.dataRollCallReducer.filterStatusCountDomains
  );

  const [dataState, setDataState] = useState({
    sort: [
      {
        field: "code",
        dir: "asc",
      },
    ],
    skip: 0,
    take: 20,
  });

  const [dataResult, setDataResult] = useState(
    process(productTypeList, dataState)
  );

  useEffect(() => {
    dispatch(
      fetchProductTypeList(filterStatusCount, filterDomains, callWithUser, filterDataProducts)
    );
    dispatch(setCallWithUser(false));
  }, [refreshCount, navigate]);

  useEffect(() => {
    setDataResult(process(productTypeList, dataState));
  }, [productTypeList]);

  const onClickFiltra = (event) => {
    // console.log ('ONCLICK')
  };

  // Open modal window
  const toggleManualValidation = () => {
    setModal(!modal);
  };

  const [modal, setModal] = useState(false);

  return (
    <>
      <Form>
        <Row className="bg-light-primary px-4 pt-2">
          <Col>
            <Row className="g-3">
              <Col>
                <div className="mb-1">
                  <Label for="categoryName">Data Products</Label>
                  <DataProductTreeSelector />
                </div>
              </Col>
              <Col>
                <div className="mb-2">
                  <Label for="categoryName">Roll-call Status</Label>
                  <RollCallStatusDomainsSelector />
                </div>
              </Col>
              <Col md="auto" className="pt-1">
                <FilterSubmit
                  color="primary"
                  className="mt-3"
                  text="Filtra"
                  onClick={onClickFiltra}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      <Container fluid className="py-2 px-4  position-relative">
        <Row>
          <Col>
            <div className="grid_task_container border">
              <Grid
                style={{ height: "100%" }}
                pageable={true}
                sortable={true}
                total={dataResult?.length}
                data={dataResult}
                {...dataState}
                groupable={true}
                pager={(props) => GridPager(props, productTypeList)}
                onDataStateChange={(e) => {
                  setDataState(e.dataState);
                  setDataResult(process(productTypeList, e.dataState));
                }}
              >
                <Column
                  {...ColumnProps("productName", dataState)}
                  title="Product"
                  columnMenu={ColumnMenu}
                  cell={(props) => AtlanCell(props)}
                />
                <Column
                  {...ColumnProps("referenceDate", dataState)}
                  title="Data riferimento attesa"
                  columnMenu={ColumnMenu}
                  
                  cell={(props) => DateCell(props, false)}
                />
                <Column
                  {...ColumnProps("windowStartTs", dataState)}
                  title="Inizio finestra"
                  columnMenu={ColumnMenu}
                  cell={(props) => DateCell(props, true)}
                />
                <Column
                  {...ColumnProps("windowEndTs", dataState)}
                  title="Fine finestra"
                  columnMenu={ColumnMenu}
                  cell={(props) => DateCell(props, true)}
                />
                <Column
                  {...ColumnProps("previousValidVersionId", dataState)}
                  title="Ultima versione valida"
                  cell={(props) => (
                    <td>
                      <a
                        style={{ cursor: "pointer" }}
                        className="fw-bold text-decoration-underline"
                        onClick={(event) => {
                          navigate(
                            "/versioni/detail/" +
                              props.dataItem.previousValidVersionId +
                              "?" +
                              searchParams
                          );
                        }}
                        onKeyDown={() => console.log("onKeyDown")}
                      >
                        {props.dataItem.previousValidVersionId}
                      </a>
                    </td>
                  )}
                  
                  columnMenu={ColumnMenu}
                />
                 <Column
                  {...ColumnProps("rollCallStatusName", dataState)}
                  title="Stato SLA"
                />
                <Column
                  {...ColumnProps("rollCallSeverityName", dataState)}
                  
                  title="Severity"
                />
                <Column
                  {...ColumnProps("previousValidReferenceDate", dataState)}
                  title="Data riferimento ultima versione valida"
                  columnMenu={ColumnMenu}
                  
                  cell={(props) => DateCell(props, false)}
                />
                <Column
                  {...ColumnProps("previousValidCreationTs", dataState)}
                  title="Creazione ultima versione valida"
                  columnMenu={ColumnMenu}
                  
                  cell={(props) => DateCell(props, true)}
                />
              </Grid>
            </div>
          </Col>
        </Row>
        <LoaderSpin isVisible={isSpinnerVisible} />
        <FilterOverlay isVisible={dataCheck} />
      </Container>
      <ModalManualValidation
        modal={modal}
        toggleManualValidation={toggleManualValidation}
      />
    </>
  );
};

export default ProductTypeListRollCall;
