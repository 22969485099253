/* eslint-disable prefer-object-spread */
import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../data/axiosInstance/axiosInstance'; 
import executor from   '../../../data/axiosInstance/executor';
import moment from "moment";
import { openAlert } from '../DataQualityStatus/DataQualitySlice';

const API_URL = `${process.env.REACT_APP_API_SINISTRI_URL}`;
const USE_API = true;

const initialState = {
  loading: false,
  filterProcessTypes:[],
  filterDQIssueTypes:{},
  processesTypes: [],
  processRunsList: [],
  processRunLogs: [],
  processVersionRunInfo: {},
  dqIssueTypes: [],
  dqIssues: [],
  lastProcessList: [],
  lastProcessRunsList: [],
  newRunParameters: [],
  selectedProcess: {}
  
};


export const ProcessRunsSlice = createSlice({
  name: 'processRuns',
  initialState,
  reducers: {
    
    //General
    toogleLoading: (state) => {
      state.loading = !state.loading;
    },

    startLoading: (state) => {
      state.loading = true
    },

    stopLoading: (state) => {
      state.loading = false
    },
    
     //Values
		getProcessesTypes: (state, action) => {
      state.processesTypes = action.payload;
    }, 
    getProcessRunLogs: (state, action) => {
      state.processRunLogs = action.payload
    },

    getProcessVersionRunInfo: (state, action) => {
      state.processVersionRunInfo = action.payload
    },
		getProcessesRunsTimeline: (state, action) => {
       state.processRunsList =  action?.payload?.map(element => {
        element.durationMs = element.durationMs/1000
        return element 
      });
    },
    getDQIssueTypes: (state, action) => {
      state.dqIssueTypes = action.payload
      state.dqIssueTypes = [{description: "Tutti"}].concat(action.payload);
    },
    getDQIssues: (state, action) => {
      state.dqIssues = action.payload; 
    },

    getLastProcessList: (state, action) => {
      state.lastProcessList = action.payload
    },
    
    getLastProcessRunsList: (state, action) => {
      state.lastProcessRunsList = action.payload
    },

    getNewRunParameters: (state, action) => {
      state.newRunParameters = action.payload
    },

    getSelectedProcess: (state, action) => {
      state.selectedProcess.processId = action.payload.processId;
      state.selectedProcess.processTypeName = action.payload.processTypeName;
    },

    //Filters
    setFilterProcessType: (state, action) => {
      state.filterProcessTypes = action.payload
    },

    setFilterDQIssueTypes: (state, action) => {
      state.filterDQIssueTypes = action.payload
    }  

  }
});


export const { 
  //General
	toogleLoading,
	startLoading,
	stopLoading,

  //Values
	getProcessesTypes,
  getProcessesRunsTimeline,
  getProcessVersionRunInfo,
  getProcessRunLogs,
  getDQIssueTypes,
  getDQIssues,
  getLastProcessList,
  getLastProcessRunsList,
  getNewRunParameters,
  getSelectedProcess,


  //Filters
  setFilterProcessType,
  setFilterDQIssueTypes
} = ProcessRunsSlice.actions;

export const fetchProcessesTypes = () => async (dispatch) => {
	console.debug ('fetchProcessesTypes...');
	dispatch(startLoading());
	dispatch(getProcessesTypes([]));
	let res
	let err
	if (USE_API){
		const API_URI_CALL = `${API_URL}/api/v1/portal/processRun/processTypes`;
		const fetchData = async  () => axiosInstance.get(API_URI_CALL);
		[res, err] = await executor.executeAsync(fetchData);
    if (err) {
      dispatch(openAlert({ message: "Errore durante il recupero dei tipi di processo: " +  err?.response?.headers?.apierror, type: "danger" }));
    }
	}
  console.debug('fetchProcessesTypes res',res); 
  dispatch (getProcessesTypes (res?.data || []));
  return dispatch(stopLoading());
};
export const fetchProcessRunsTimeline = (fromDate, toDate, processTypes, domains, extendedStatusList, dataProducts) => async(dispatch) =>{
  console.debug('fetchProcessRunsTimeline...')
  dispatch(startLoading());
  dispatch(getProcessesRunsTimeline([]));
  let res
	let err
	if (USE_API){
		const API_URI_CALL = `${API_URL}/api/v1/portal/processRun/runStatus`;
    const fetchData = async  () => axiosInstance.post(API_URI_CALL, {extendedStatusList: extendedStatusList, domainList: domains, productList: dataProducts || []}, { params:{
			startDate : fromDate,
			endDate : toDate,
      processType : processTypes
		}});
		[res, err] = await executor.executeAsync(fetchData)
    if (err) {
      dispatch(openAlert({ message: "Errore durante il recupero della Timeline: " +  err?.response?.headers?.apierror, type: "danger" }));
    }

  } else  {
    //to Load local json without API
  }
  
  console.debug('fetchProcessRunsTimeline res',res); 
  dispatch (getProcessesRunsTimeline (res?.data || []));
  return dispatch(stopLoading());

};
export const fetchProcessRunLogs = (versionId) => async (dispatch) => {
	console.debug ('fetchProcessRunLogs ('+versionId+')...');
	dispatch(startLoading());
	dispatch(getProcessRunLogs([]));
	let res
	let err
	if (USE_API){
		const API_URI_CALL = `${API_URL}/api/v1/portal/processRun/runLog`;
		const fetchData = async  () => axiosInstance.get(API_URI_CALL, { 
      params:{
        versionId : versionId,
      }
    });
		[res, err] = await executor.executeAsync(fetchData);
    if (err) {
      dispatch(openAlert({ message: "Errore durante il recupero dei log: " +  err?.response?.headers?.apierror, type: "danger" }));
    }
	}

  console.debug('fetchProcessRunLogs res',res); 
  dispatch (getProcessRunLogs (res?.data || []));
  return dispatch(stopLoading());
};
export const fetchProcessVersionRunInfo = (versionId) => async (dispatch) => {
	console.debug ('fetchProcessVersionRunInfo ('+versionId+')...');
	dispatch(startLoading());
	dispatch(getProcessVersionRunInfo([]));
	let res
	let err
	if (USE_API){
		const API_URI_CALL = `${API_URL}/api/v1/portal/processRun/versionRunInfo`;
		const fetchData = async  () => axiosInstance.get(API_URI_CALL, { 
      params:{
        versionId : versionId,
      }
    });
		[res, err] = await executor.executeAsync(fetchData);
    if (err) {
      dispatch(openAlert({ message: "Errore durante il recupero della Run Info: " +  err?.response?.headers?.apierror, type: "danger" }));
    }
	}else{
	
	}

  console.debug('fetchProcessVersionRunInfo res',res); 
  dispatch (getProcessVersionRunInfo (res?.data || []));
  return dispatch(stopLoading());
};
export const fetchDQIssueTypes = () => async (dispatch) => {
	console.debug ('fetchDQIssueTypes...');
	dispatch(startLoading());
	dispatch(getDQIssueTypes([]));
	let res
	let err
	if (USE_API){
		const API_URI_CALL = `${API_URL}/api/v1/portal/processRun/dqIssueTypes`;
		const fetchData = async  () => axiosInstance.get(API_URI_CALL);
		[res, err] = await executor.executeAsync(fetchData);
    if (err) {
      dispatch(openAlert({ message: "Errore durante il recupero dei DQ Types: " +  err?.response?.headers?.apierror, type: "danger" }));
    }
	}
  console.debug('fetchDQIssueTypes res',res); 
  dispatch (getDQIssueTypes (res?.data || []));
  return dispatch(stopLoading());
};
export const fetchDQIssues = (filterMese, issueType) => async(dispatch) =>{
  console.debug('fetchDQIssues...')
  dispatch(startLoading());
  dispatch(getDQIssues([]));
  let res
	let err
	if (USE_API){
		const API_URI_CALL = `${API_URL}/api/v1/portal/processRun/dqIssues`;
    const fetchData = async  () => axiosInstance.get(API_URI_CALL, { params:{
			startDate : moment().subtract(filterMese, 'month').startOf('month').format('YYYY-MM-DD'),
			endDate :  moment().subtract(filterMese, 'month').endOf('month').format('YYYY-MM-DD'),
      issueType : issueType?.id
		}});
		[res, err] = await executor.executeAsync(fetchData)
    if (err) {
      dispatch(openAlert({ message: "Errore durante il recupero delle DQ Issue: " +  err?.response?.headers?.apierror, type: "danger" }));
    }

  } else  {
    //to Load local json without API
  }
  
  console.debug('fetchDQIssues res',res); 
  dispatch (getDQIssues (res?.data || []));
  return dispatch(stopLoading());

};

export const fetchLastProcessList = () => async (dispatch) => {
  console.debug ('fetchLastProcessList...');
  dispatch(startLoading());
  dispatch(getLastProcessList([]));
  let res
  let err
  if (USE_API){
    const API_URI_CALL = `${API_URL}/api/v1/portal/processRun/processList`;
    const fetchData = async  () => axiosInstance.get(API_URI_CALL);
    [res, err] = await executor.executeAsync(fetchData);
    if (err) {
      dispatch(openAlert({ message: "Errore durante il recupero degli ultimi processi: " +  err?.response?.headers?.apierror, type: "danger" }));
    }
  }
  console.debug('fetchLastProcessList res',res); 
  dispatch (getLastProcessList (res?.data || []));
  return dispatch(stopLoading());
}

export const fetchLastProcessRuns = (processUuids, processType, intervallo) => async (dispatch) => {
  console.debug ('fetchLastProcessRuns...');
  dispatch(startLoading());
  dispatch(getLastProcessRunsList([]));
  let res
  let err
  if (USE_API){
    const API_URI_CALL = `${API_URL}/api/v1/portal/processRun/latestRuns`;
    const fetchData = async  () => axiosInstance.post(API_URI_CALL, {  processUuids : [processUuids] }, { 
      params:{
       startDate:  moment().subtract(intervallo, 'hours').format ('YYYY-MM-DD HH:mm:ss'),
       endDate: moment().format ('YYYY-MM-DD HH:mm:ss'),
       processType: processType
      }
    });
    [res, err] = await executor.executeAsync(fetchData);
    if (err) {
      dispatch(openAlert({ message: "Errore durante il recupero degli ultimi processi: " +  err?.response?.headers?.apierror, type: "danger" }));
    }
  }

  console.debug('fetchLastProcessRuns res',res); 
  dispatch (getLastProcessRunsList (res?.data || []));
  return dispatch(stopLoading());
}

export const fetchNewRunParameters = (processId, revisionNum) => async (dispatch) => {
  console.debug ('fetchNewRunParameters...');
  dispatch(startLoading());
  dispatch(getNewRunParameters([]));
  let res
  let err
  if (USE_API){
    const API_URI_CALL = `${API_URL}/api/v1/portal/processRun/processParameters`;
    const fetchData = async  () => axiosInstance.get(API_URI_CALL, { 
      params:{
        processId : processId,
        revisionNum : revisionNum
      }
    });
    [res, err] = await executor.executeAsync(fetchData);
    if (err) {
      dispatch(openAlert({ message: "Errore durante il recupero dei parametri: " +  err?.response?.headers?.apierror, type: "danger" }));
    }
  }
  console.debug('fetchNewRunParameters res',res); 
  dispatch (getNewRunParameters (  res?.data.length > 0 ? res.data : [
    {
      "optional": true,
      "process_id": "string",
      "revision_num": "string",
      "name": "string",
      "description": "string",
      "is_optional": true,
      "expression": "string",
      "ins_ts": "string",
      "upd_ts": "string"
    }
  ]));
  return dispatch(stopLoading());
}





export default ProcessRunsSlice.reducer;
