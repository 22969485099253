/* eslint-disable prefer-object-spread */
import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../data/axiosInstance/axiosInstance";
import executor from "../../../data/axiosInstance/executor";
import moment from "moment";
import jwtDdecode from "jwt-decode";

import fetchDomainsJson from "./DataQualitySlice.fetchDomains.json";
import fetchVersionInfoJson from "./DataQualitySlice.fetchVersionInfo.json";
import fetchVersionDqListJson from "./DataQualitySlice.fetchVersionDqList.json";
import fetchExtendedStatsJson from "./DataQualitySlice.fetchExtendedStats.json";
import fetchKQIJson from "./DataQualitySlice.fetchKQI.json";
import fetchVersionsListJson from "./DataQualitySlice.fetchVersionsList.json";
import fetchVersionStatusesJson from "./DataQualitySlice.fetchVersionStates.json";

const API_URL = `${process.env.REACT_APP_API_SINISTRI_URL}`;

const initialState = {
  dataCheck: false,
  loading: false,
  refreshCount: 0,
  filterIntervallo: 24, // ultime 24 ore
  filterMese: 0, // mese corrente
  filterDataDa: moment().subtract(24, "hours").format("YYYY-MM-DD HH:mm:ss"),
  // filterDataDa: '2023-09-01 00:00:00',
  filterDataA: moment().format("YYYY-MM-DD HH:mm:ss"),
  filterDomains: [],
  filterQuery: [],
  filterDimensions: [],
  filterExtendedStatsDomains: [],
  filterOtherVersion: {},
  domains: [],
  primaryStats: [],
  extendedStats: [],
  kqi: [],
  kqiToDisplay: [],
  versionsList: [],
  versionInfo: [],
  duplicatedVersionInfo: [],
  versionValidationHistory: [],
  versionDqList: [],
  versionsStatuses: [],
  payloadStructure: [],
  summaryStructure: [],
  payload: [],
  dimensions: [],
  summary: [],
  summaryCounts: [],
  checkSummary: false,
  extendedStatsDomains: [],
  versionsCount: 0,
  visiblesIds: 99,
  waterFall: [],
  alertValues: {
    isOpen: false,
    message: "",
    type: "",
    requestId: "",
    clickable: false,
  },
  otherVersions: [],
  comparedVersions: [],
  keys: [],
  filterKeys: {},
  keyChanges: [],
  versionDownloaded: null,
  fileDownloading: false,
  downloadedFile: null,
  dataProducts: [],
  filterDataProducts: [],
  userInitial: "R",
  versionLineage: [],
  dataLineage: [],

};

const USE_API = true;

export const DataQualitySlice = createSlice({
  name: "dataQuality",
  initialState,
  reducers: {
    closeAlert: (state) => {
      state.alertValues.isOpen = false;
    },
    openAlert: (state, action) => {
      state.alertValues.isOpen = true;
      state.alertValues.message = action.payload.message;
      state.alertValues.type = action.payload.type;
      state.alertValues.requestId = action.payload.requestId;
      state.alertValues.clickable = action.payload.clickable || false;
    },
    toogleLoading: (state) => {
      state.loading = !state.loading;
    },

    toogleDataCheck: (state, action) => {
      state.dataCheck = action.payload;
    },

    setRefreshCount: (state, action) => {
      state.refreshCount = action.payload;
    },
    setVisiblesIds: (state, action) => {
      state.visiblesIds = action.payload;
    },
    startLoading: (state) => {
      state.loading = true;
    },

    stopLoading: (state) => {
      state.loading = false;
    },

    setFilterVersionStatus: (state, action) => {
      state.filterVersionStatus = action.payload;
    },

    setFilterDomains: (state, action) => {
      state.filterDomains = action.payload;
    },

    setFilterIntervallo: (state, action) => {
      state.filterIntervallo = action.payload;
    },
    setFilterMese: (state, action) => {
      state.filterMese = action.payload;
    },

    setFilterDataDa: (state, action) => {
      state.filterDataDa = moment(action.payload).format("YYYY-MM-DD HH:mm:ss");
    },

    setFilterDataA: (state, action) => {
      state.filterDataA = moment(action.payload).format("YYYY-MM-DD HH:mm:ss");
    },

    setFilterDimensions: (state, action) => {
      state.filterDimensions = action.payload;
    },
    setFilterExtendedStatsDomains: (state, action) => {
      state.filterExtendedStatsDomains = action.payload;
    },
    getDomains: (state, action) => {
      state.domains = action.payload;
    },

    getPrimaryStats: (state, action) => {
      state.primaryStats = {
        valid: action?.payload?.find((item) => item.id == 1) || { count: 0 },
        pending: action?.payload?.find((item) => item.id == -1) || { count: 0 },
        invalid: action?.payload?.find((item) => item.id == 0) || { count: 0 },
      };
      console.log(state.primaryStats);
    },

    getExtendedStats: (state, action) => {
      state.extendedStats = action.payload;
      console.log(state.extendedStats);
    },

    getVersionsStatuses: (state, action) => {
      state.versionsStatuses = action.payload;
    },

    getVersionsList: (state, action) => {
      state.versionsList = action.payload;
    },

    getVersionInfo: (state, action) => {
      state.versionInfo = action.payload;
    },

    getDuplicatedVersionInfo: (state, action) => {
      state.duplicatedVersionInfo = action.payload;
    },

    getVersionDqList: (state, action) => {
      state.versionDqList = action.payload;
    },

    getVersionValidationHistory: (state, action) => {
      state.versionValidationHistory = action.payload;
    },

    getKQI: (state, action) => {
      state.kqi = action.payload;
    },

    getKQItoDisplay: (state, action) => {
      state.kqiToDisplay = action.payload;
    },

    getDimensions: (state, action) => {
      state.dimensions = action.payload;
    },
    getPayloadStructure: (state, action) => {
      state.payloadStructure = action.payload;
    },
    getPayload: (state, action) => {
      let payload = action?.payload?.map((x) => x.row);
      let gridArray = [];

      payload?.forEach((x) => {
        let test = {};
        x.forEach(
          (accumulator) => (test[accumulator.name] = accumulator.value)
        );
        gridArray.push(test);
      });

      state.payload = gridArray;
    },
    getSummary: (state, action) => {
      let summary = action?.payload?.map((x) => x.row);
      let gridArray = [];

      summary?.forEach((x) => {
        let test = {};
        x.forEach(
          (accumulator) => (test[accumulator.name] = accumulator.value)
        );
        gridArray.push(test);
      });

      state.summary = gridArray;
    },
    getSummaryCounts: (state, action) => {
      state.summaryCounts = action.payload?.row;
    },
    getSummaryStructure: (state, action) => {
      state.summaryStructure = action.payload;
    },
    getVersionsCount: (state, action) => {
      state.versionsCount = action.payload;
    },
    getExtendedStatsDomains: (state, action) => {
      state.extendedStatsDomains = action.payload;
    },
    getWaterfall: (state, action) => {
      state.waterFall = action.payload;
    },
    getOtherVersions: (state, action) => {
      state.otherVersions = action.payload;

      if (action.payload && action.payload.length > 0) {
        state.filterOtherVersion = action.payload[0];
      }
    },
    setFilterOtherVersion: (state, action) => {
      state.filterOtherVersion = action.payload;
    },
    getComparedVersions: (state, action) => {
      state.comparedVersions = action.payload;
    },
    getKeys: (state, action) => {
      state.keys = action.payload;

      if (action.payload && action.payload.length > 0) {
        state.filterKeys = action.payload[0];
      }
    },
    setFilterKeys: (state, action) => {
      state.filterKeys = action.payload;
    },
    getKeyChanges: (state, action) => {
      state.keyChanges = action.payload;
    },
    setVersionDownloaded: (state, action) => {
      state.versionDownloaded = action.payload;
    },
    setFileDownloading: (state, action) => {
      state.fileDownloading = action.payload;
    },
    setDownloadedFile: (state, action) => {
      state.downloadedFile = action.payload;
    },

    getDataProducts: (state, action) => {
      state.dataProducts = action.payload;
    },
    setFilterDataProducts: (state, action) => {
      state.filterDataProducts = action.payload;
    },
    setUserInitial: (state, action) => {  
      state.userInitial = action.payload;
    },
    getVersionLineage: (state, action) => {
      state.versionLineage = action.payload;
    },
    getDataLineage: (state, action) => {
      state.dataLineage = action.payload;
    },
  },
});

export const {
  setRefreshData,
  setRefreshCount,
  setVisiblesIds,
  closeAlert,
  openAlert,
  toogleLoading,
  toogleDataCheck,
  startLoading,
  stopLoading,
  getDomains,
  getPrimaryStats,
  getExtendedStats,
  getKQI,
  getKQItoDisplay,
  getVersionsList,
  getVersionInfo,
  getDuplicatedVersionInfo,
  getVersionDqList,
  getVersionValidationHistory,
  getVersionsStatuses,
  getExtendedStatsDomains,
  setFilterIntervallo,
  setFilterMese,
  setFilterDomains,
  setFilterDataDa,
  setFilterDataA,
  setFilterVersionStatus,
  setFilterDimensions,
  setFilterExtendedStatsDomains,
  getPayloadStructure,
  getPayload,
  getDimensions,
  getSummary,
  getSummaryCounts,
  getSummaryStructure,
  getVersionsCount,
  getWaterfall,
  getOtherVersions,
  setFilterOtherVersion,
  getComparedVersions,
  getKeys,
  setFilterKeys,
  getKeyChanges,
  setVersionDownloaded,
  setFileDownloading,
  setDownloadedFile,
  getDataProducts,
  setFilterDataProducts,
  setUserInitial,
  getVersionLineage,
  getDataLineage,
} = DataQualitySlice.actions;

/**
 * chiamata APi per recuperare l'elenco di domini
 * @returns https://docs.google.com/document/d/1NtARovXwT5x6HlsdbQzz7QGrSKjE__wdTpTuKsOfelo/edit
 */
export const fetchDomains = () => async (dispatch) => {
  dispatch(startLoading());
  dispatch(getDomains([]));
  let res = null;
  let err = null;
  if (USE_API) {
    const API_URI_CALL = `${API_URL}/api/v1/portal/domain/getDomains`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);
    [res, err] = await executor.executeAsync(fetchData);
    if (err) {
      dispatch(
        openAlert({
          message:
            "Errore durante il recupero dei dominii:  " +
            err?.response?.headers?.apierror,
          type: "danger",
        })
      );
    }
    res?.data?.unshift({
      domainDescription: "Tutti",
      domainName: null,
      domainId: null,
    });
  } else {
    res = fetchDomainsJson;
  }

  console.debug("fetchDomains res", res);

  dispatch(getDomains(res?.data || []));
  return dispatch(stopLoading());
};

/**
 * chiamata APi per recuperare le info su una determinata versione
 * @param {integer} versionId L'id della versione
 * @returns https://drive.google.com/file/d/17Ogb3cfwu-0gq70RBAeCl-H7msjJKM9_/view
 */
export const fetchVersionInfo = (versionId) => async (dispatch) => {
  dispatch(startLoading());
  dispatch(getVersionInfo({}));
  dispatch(getDuplicatedVersionInfo({}));

  let res = null;
  let err = null;
  if (USE_API) {
    const API_URI_CALL = `${API_URL}/api/v1/portal/versions/info/${versionId}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);
    [res, err] = await executor.executeAsync(fetchData);
    if (err) {
      dispatch(
        openAlert({
          message:
            "Errore durante il recupero delle informazioni della versione: " +
            err?.response?.headers?.apierror,
          type: "danger",
        })
      );
    }
  } else {
    res = fetchVersionInfoJson;
  }

  console.debug("fetchVersionInfo res", res);
  if (res?.data?.replaced_by) {
    // se la versione è duplicata recupero anche le info della versione dalla quale duplica
    const API_URI_CALL = `${API_URL}/api/v1/portal/versions/info/${res.data.replaced_by}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);
    const [dupres, duperr] = await executor.executeAsync(fetchData);
    if (err) {
      dispatch(
        openAlert({
          message:
            "Errore durante il recupero delle informazioni della versione duplicata: " +
            err?.response?.headers?.apierror,
          type: "danger",
        })
      );
    }
    dispatch(getDuplicatedVersionInfo(dupres.data));
  }

  dispatch(getVersionInfo(res?.data || []));
  return dispatch(stopLoading());
};

/**
 * chiamata APi per recuperare l'elenco dei rsultati automatici di una versione
 * @param {integer} versionId L'id della versione
 * @returns https://docs.google.com/document/d/1Ttx5UDLE8GfzwqgryKMebt7ezD2ZAmUDfbdfNtWOKfk/edit?usp=sharing
 */
export const fetchVersionDqList = (versionId) => async (dispatch) => {
  dispatch(startLoading());
  dispatch(getVersionDqList([]));

  let res = null;
  let err = null;
  if (USE_API) {
    const API_URI_CALL = `${API_URL}/api/v1/portal/versions/dq/${versionId}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);
    [res, err] = await executor.executeAsync(fetchData);
    if (err) {
      dispatch(
        openAlert({
          message:
            "Errore durante il recupero della Data Quality: " +
            err?.response?.headers?.apierror,
          type: "danger",
        })
      );
    }
  } else {
    res = fetchVersionDqListJson;
  }

  console.debug("fetchVersionDqList res", res);
  dispatch(getVersionDqList(res?.data || []));
  return dispatch(stopLoading());
};

/**
 * chiamata APi per recuperare l'elenco dei risultati manuali di una versione
 * @param {integer} versionId L'id della versione
 * @returns https://drive.google.com/file/d/1dA3bEl3Uem5DmrF4Ds657weMK2HGEdng/view?usp=sharing
 */
export const fetchVersionValidationHistory =
  (versionId) => async (dispatch) => {
    dispatch(startLoading());
    dispatch(getVersionValidationHistory([]));

    let res;
    let err;
    if (USE_API) {
      const API_URI_CALL = `${API_URL}/api/v1/portal/versions/validationHistory/${versionId}`;
      const fetchData = async () => axiosInstance.get(API_URI_CALL);
      [res, err] = await executor.executeAsync(fetchData);
      if (err) {
        dispatch(
          openAlert({
            message:
              "Errore durante il recupero della storia di validazione: " +
              err?.response?.headers?.apierror,
            type: "danger",
          })
        );
      }
    } else {
      res = fetchVersionDqListJson;
    }

    console.debug("fetchVersionValidationHistory res", res);
    dispatch(getVersionValidationHistory(res?.data || []));
    return dispatch(stopLoading());
  };

/**
 * chiamata API per recuperare l'elenco degli stati delle versioni
 * @param {string YYYY-MM-DD HH:mm:ss} fromDate  La data di partenza
 * @param {string YYYY-MM-DD HH:mm:ss} toDate  La data finale
 * @param {array} domains un elenco di uuid di domini
 * @returns https://drive.google.com/file/d/18yqcIGZU0O4vpwQZwwiLY1RZbVh9Sxke/view?usp=sharing
 */
export const fetchPrimaryStats =
  (fromDate, toDate, domains, dataProducts, extendedStats) =>
  async (dispatch) => {
    dispatch(startLoading());
    console.debug(
      "fetchPrimaryStats " + fromDate + " / " + toDate + " / " + domains
    );
    dispatch(getPrimaryStats([]));

    let res;
    let err;
    if (USE_API) {
      const API_URI_CALL = `${API_URL}/api/v1/portal/versions/versionsStatus`;
      const fetchData = async () =>
        axiosInstance.post(
          API_URI_CALL,
          {
            domanisList: domains || [],
            productList: dataProducts || [],
            extendedStatusList: extendedStats || [],
          },
          {
            params: {
              startDate: fromDate,
              endDate: toDate,
            },
          }
        );
      [res, err] = await executor.executeAsync(fetchData);
      if (err) {
        dispatch(
          openAlert({
            message:
              "Errore durante il recupero delle informazioni delle versioni: " +
              err?.response?.headers?.apierror,
            type: "danger",
          })
        );
      }
    } else {
      res = fetchExtendedStatsJson;
    }

    console.debug("fetchPrimaryStats::res", res);
    dispatch(getPrimaryStats(res?.data || []));
    return dispatch(stopLoading());
  };

/**
 * chiamata APi per recuperare l'elenco degli stati estesi delle versioni
 * @param {string YYYY-MM-DD HH:mm:ss} fromDate  La data di partenza
 * @param {string YYYY-MM-DD HH:mm:ss} toDate  La data finale
 * @param {array} domains un elenco di uuid di domini
 * @returns https://drive.google.com/file/d/1vggznJrJ4exwU2cdwA0Cj6gTGx4er7vE/view?usp=drive_link
 */
export const fetchExtendedStats =
  (fromDate, toDate, domains, dataProducts, extendedStats) =>
  async (dispatch) => {
    dispatch(startLoading());
    console.debug(
      "fetchExtendedStats " + fromDate + " / " + toDate + " / " + domains
    );
    dispatch(getExtendedStats([]));

    let res;
    let err;
    if (USE_API) {
      const API_URI_CALL = `${API_URL}/api/v1/portal/versions/versionsExtendedStatus`;
      const fetchData = async () =>
        axiosInstance.post(
          API_URI_CALL,
          {
            domainList: domains || undefined,
            productList: dataProducts || [],
            extendedStatusList: extendedStats || [],
          },
          {
            params: {
              startDate: fromDate,
              endDate: toDate,
            },
          }
        );
      [res, err] = await executor.executeAsync(fetchData);
      if (err) {
        dispatch(
          openAlert({
            message:
              "Errore durante il recupero delle informazioni estese delle versioni: " +
              err?.response?.headers?.apierror,
            type: "danger",
          })
        );
      }
    } else {
      res = fetchExtendedStatsJson;
    }

    res.data?.sort((item1, item2) => item2.status_id - item1.status_id);

    console.debug("fetchExtendedStats res", res);
    dispatch(getExtendedStats(res?.data || []));
    return dispatch(stopLoading());
  };

/**
 * chiamata API per recuperare i KQI
 * @param {string YYYY-MM-DD HH:mm:ss} fromDate  La data di partenza
 * @param {string YYYY-MM-DD HH:mm:ss} toDate  La data finale
 * @param {array} domains un elenco di uuid di domini
 * @returns https://drive.google.com/file/d/19hhyAMZiYwAShG49Jw_b_qPkFIxMlZl0/view
 */
export const fetchKQI =
  (fromDate, toDate, domains, dataProducts, extendedStats) =>
  async (dispatch) => {
    dispatch(startLoading());
    console.debug("fetchKQI " + fromDate + " / " + toDate + " / " + domains);
    dispatch(getKQI([]));
    let res;
    let err;
    if (USE_API) {
      const API_URI_CALL = `${API_URL}/api/v1/portal/versions/kqi`;
      const fetchData = async () =>
        axiosInstance.post(
          API_URI_CALL,
          {
            domainList: domains || undefined,
            productList: dataProducts || [],
            extendedStatusList: extendedStats || [],
          },
          {
            params: {
              startDate: fromDate,
              endDate: toDate,
            },
          }
        );
      [res, err] = await executor.executeAsync(fetchData);
      if (err) {
        console.log("fetchKQI err", err);
        dispatch(
          openAlert({
            message:
              "Errore durante il recupero delle informazioni dei KQI: " +
              err?.response?.headers?.apierror,
            type: "danger",
          })
        );
      }
    } else {
      res = fetchKQIJson;
    }

    console.debug("fetchKQI res", res);
    const ret = res?.data?.reduce((accumulator, item) => {
      if (item.id_parent == null) {
        accumulator.push({
          data: item,
          childs: res.data.filter((child) => child.id_parent == item.id),
        });
      }
      return accumulator;
    }, []);
    const iDsToDisplay = [18, 16, 17, 20, 13, 11, 12, 15, 10, 9, 19, 21];
    const ret2 = [];
    res?.data?.forEach((item) =>
      iDsToDisplay.includes(item.id) ? ret2.push(item) : null
    );

    ret2.sort((a, b) => {
      return iDsToDisplay.indexOf(a.id) - iDsToDisplay.indexOf(b.id);
    });

    console.log("kqiToDisplay", ret2);

    console.debug("fetchKQI ret", ret);
    dispatch(getKQI(ret || []));
    dispatch(getKQItoDisplay(ret2 || []));
    return dispatch(stopLoading());
  };

/**
 * chiamata API per recuperare l'elenco delle versioni di un determinato status
 * @param {string YYYY-MM-DD HH:mm:ss} fromDate  La data di partenza
 * @param {string YYYY-MM-DD HH:mm:ss} toDate  La data finale
 * @param {array} domains un elenco di uuid di domini
 * @returns https://drive.google.com/file/d/19hhyAMZiYwAShG49Jw_b_qPkFIxMlZl0/view
 */
export const fetchVersionsList =
  (fromDate, toDate, domains, status, dataProducts) => async (dispatch) => {
    console.debug(
      "fetchVersionsList " +
        fromDate +
        " / " +
        toDate +
        " / " +
        domains +
        " / " +
        status
    );
    dispatch(startLoading());
    dispatch(getVersionsList([]));
    let res;
    let err;
    if (USE_API) {
      const API_URI_CALL = `${API_URL}/api/v1/portal/versions/list`;
      const fetchData = async () =>
        axiosInstance.post(
          API_URI_CALL,
          {
            domainList: domains || [],
            productList: dataProducts || [],
            extendedStatusList: status || [],
          },
          {
            params: {
              startDate: fromDate,
              endDate: toDate,
            },
          }
        );
      [res, err] = await executor.executeAsync(fetchData);
      if (err) {
        dispatch(
          openAlert({
            message:
              "Errore durante il recupero della lista versioni: " +
              err?.response?.headers?.apierror,
            type: "danger",
          })
        );
      }
    } else {
      res = fetchVersionsListJson;
    }

    console.debug("getVersionsList res", res);
    dispatch(getVersionsList(res?.data || []));
    return dispatch(stopLoading());
  };

/**
 * REQF0106 - Manual validation
 * @param {*} fromDate
 * @param {*} toDate
 * @param {*} domains
 * @param {*} status
 * @returns
 */
export const saveManualVersion =
  (check, outcome, note, versionId, file, replacedVersionId, key) =>
  async (dispatch) => {
    console.debug("saveManualVersion");
    dispatch(startLoading());

    const currentuser = JSON.parse(localStorage.getItem("userInfo"));
    let decoded = {};

    if (currentuser) {
      decoded = jwtDdecode(currentuser.access_token);
    } else {
      decoded.name = "";
      decoded.email = "";
    }

    const formData = new FormData();

    formData.append("file", file);

    if (USE_API) {
      let API_URI_CALL = `${API_URL}/api/v1/portal/versions/saveManualVersion`;

      // Wrap the axios call in a Promise
      return new Promise((resolve, reject) => {
        axiosInstance
          .post(API_URI_CALL, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            params: {
              outcome: outcome ? 1 : 0,
              note: note,
              versionId: versionId,
              replacedVersionId: replacedVersionId,
              key: key,
              user: decoded.email,
              checkMode: check,
            },
          })
          .then((response) => {
            if (response.data?.esito) resolve(response.data);
            // Resolve with the response data
            else {
              dispatch(
                openAlert({
                  message: "Errore: " + response.data.msgDesc,
                  type: "danger",
                })
              );
              throw response.data;
            } //Force catch with error message
          })
          .catch((error) => {
            if (!check) {
              dispatch(
                openAlert({
                  message: "Errore durante il salvataggio: " + error.msgDesc,
                  type: "danger",
                })
              );
            }
            reject(error); // Reject with the error
          })
          .finally(() => {
            dispatch(stopLoading());
          });
      });
    }
  };

export const fetchPayload = (versionId) => async (dispatch) => {
  console.debug("fetchPayload ");
  dispatch(startLoading());
  dispatch(getPayload([]));
  let res = null;
  let err = null;
  if (USE_API) {
    let API_URI_CALL = `${API_URL}/api/v1/portal/singleVersion/payload/${versionId}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);
    [res, err] = await executor.executeAsync(fetchData);
    if (err) {
      dispatch(
        openAlert({
          message:
            "Errore durante il recupero del payload: " +
            err?.response?.headers?.apierror,
          type: "danger",
        })
      );
    }
  } else {
    res = fetchVersionStatusesJson;
  }

  console.debug("fetchPayload res", res);
  dispatch(getPayload(res.data));
  return dispatch(stopLoading());
};
export const fetchDimensions = (versionId) => async (dispatch) => {
  console.debug("fetchDimensions ");
  dispatch(startLoading());
  dispatch(getDimensions([]));
  let res = null;
  let err = null;
  if (USE_API) {
    let API_URI_CALL = `${API_URL}/api/v1/portal/singleVersion/dimensions/${versionId}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);
    [res, err] = await executor.executeAsync(fetchData);
    if (err) {
      dispatch(
        openAlert({
          message:
            "Errore durante il recupero delle dimensioni: " +
            err?.response?.headers?.apierror,
          type: "danger",
        })
      );
    }
  } else {
    res = fetchVersionStatusesJson;
  }

  console.debug("fetchDimensions res", res);
  dispatch(getDimensions(res.data));
  return dispatch(stopLoading());
};
export const fetchSummary = (versionId, dimensions) => async (dispatch) => {
  console.debug("fetchSummary ");
  dispatch(startLoading());
  dispatch(getSummary([]));
  let res = null;
  let err = null;

  if (USE_API) {
    let API_URI_CALL = `${API_URL}/api/v1/portal/singleVersion/summary/${versionId}`;
    const fetchData = async () =>
      axiosInstance.post(API_URI_CALL, { values: dimensions });
    [res, err] = await executor.executeAsync(fetchData);
    if (err) {
      dispatch(
        openAlert({
          message:
            "Errore durante il recupero del summary: " +
            err?.response?.headers?.apierror,
          type: "danger",
        })
      );
    }
  } else {
    console.log("non chiamo summary perchè dimension = 0");
  }

  console.debug("fetchSummary res", res);
  dispatch(getSummary(res.data));
  return dispatch(stopLoading());
};

export const fetchPayloadStructure =
  (versionId, dimensions) => async (dispatch) => {
    console.debug("fetchPayloadStructure ");
    dispatch(startLoading());
    dispatch(getPayloadStructure([]));
    let res = null;
    let err = null;
    if (USE_API) {
      let API_URI_CALL = `${API_URL}/api/v1/portal/singleVersion/payloadStructure/${versionId}`;
      const fetchData = async () => axiosInstance.get(API_URI_CALL);
      [res, err] = await executor.executeAsync(fetchData);
      if (err) {
        dispatch(
          openAlert({
            message:
              "Errore durante il recupero della Payload Structure: " +
              err?.response?.headers?.apierror,
            type: "danger",
          })
        );
      }
    }
    console.debug("fetchPayloadStructure res", res);
    dispatch(getPayloadStructure(res.data));
    return dispatch(stopLoading());
  };

export const fetchSummaryStructure =
  (versionId, dimensions) => async (dispatch) => {
    console.debug("fetchSummaryStructure ");
    dispatch(startLoading());
    dispatch(getSummaryStructure([]));
    let res = null;
    let err = null;

    if (USE_API) {
      let API_URI_CALL = `${API_URL}/api/v1/portal/singleVersion/summaryStructure/${versionId}`;
      const fetchData = async () =>
        axiosInstance.post(API_URI_CALL, {
          values: dimensions.map((x) => x.id),
        });
      [res, err] = await executor.executeAsync(fetchData);
      if (err) {
        dispatch(
          openAlert({
            message:
              "Errore durante il recupero della Summary Structure: " +
              err?.response?.headers?.apierror,
            type: "danger",
          })
        );
      }
    } else {
      console.log("non chiamo summary perchè dimension = 0");
    }

    console.debug("fetchSummaryStructure res", res);
    dispatch(getSummaryStructure(res.data));
    return dispatch(stopLoading());
  };
export const fetchVersionsCount =
  (fromDate, toDate, domains, dataProducts, extendedStats) =>
  async (dispatch) => {
    console.debug("DataQualityStatus::fetchVersionsCount");
    dispatch(startLoading());
    dispatch(getVersionsCount([]));

    let res = null;
    let err = null;
    if (USE_API) {
      const API_URI_CALL = `${API_URL}/api/v1/portal/versions/count`;
      const fetchData = async () =>
        axiosInstance.post(
          API_URI_CALL,
          {
            domainList: domains || [],
            productList: dataProducts || [],
            extendedStatusList: extendedStats || [],
          },
          {
            params: {
              startDate: fromDate,
              endDate: toDate,
            },
          }
        );
      [res, err] = await executor.executeAsync(fetchData);
      if (err) {
        dispatch(
          openAlert({
            message:
              "Errore durante il recupero del count sulle versioni: " +
              err?.response?.headers?.apierror,
            type: "danger",
          })
        );
      }
    } else {
      res = {};
    }
    dispatch(getVersionsCount(res?.data || 0));
    return dispatch(stopLoading());
  };

export const fetchExtendedStatsDomains = () => async (dispatch) => {
  dispatch(startLoading());
  dispatch(getExtendedStatsDomains([]));
  let res = null;
  let err = null;
  if (USE_API) {
    const API_URI_CALL = `${API_URL}/api/v1/portal/versions/extStatusDomain`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);
    [res, err] = await executor.executeAsync(fetchData);
    if (err) {
      dispatch(
        openAlert({
          message:
            "Errore durante il recupero degli Stati: " +
            err?.response?.headers?.apierror,
          type: "danger",
        })
      );
    }
    res?.data?.unshift({ name: "Tutti", status_name: null, id: null });
  }

  console.debug("fetchDomains res", res);

  dispatch(getExtendedStatsDomains(res?.data || []));
  return dispatch(stopLoading());
};

export const fetchSummaryCounts = (versionId) => async (dispatch) => {
  console.debug("fetchSummaryCounts ");
  dispatch(startLoading());
  dispatch(getSummaryCounts([]));
  let res = null;
  let err = null;

  if (USE_API) {
    let API_URI_CALL = `${API_URL}/api/v1/portal/singleVersion/summaryCount/${versionId}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);
    [res, err] = await executor.executeAsync(fetchData);
    if (err) {
      dispatch(
        openAlert({
          message:
            "Errore durante il recupero di Summary Count: " +
            err?.response?.headers?.apierror,
          type: "danger",
        })
      );
    }
  } else {
    console.log("non chiamo summary perchè dimension = 0");
  }

  console.debug("fetchSummaryCounts res", res);
  dispatch(getSummaryCounts(res.data));
  return dispatch(stopLoading());
};

export const fetchWaterfall =
  (fromDate, toDate, domains, dataProducts, extendedStats) =>
  async (dispatch) => {
    console.log("fetchWaterfall " + fromDate + " / " + toDate);
    dispatch(startLoading());
    dispatch(getWaterfall([]));
    let res = null;
    let err = null;
    if (USE_API) {
      let API_URI_CALL = `${API_URL}/api/v1/portal/versions/waterfall`;
      const fetchData = async () =>
        axiosInstance.post(
          API_URI_CALL,
          {
            domainList: domains || undefined,
            productList: dataProducts || [],
            extendedStatusList: extendedStats || [],
          },
          {
            params: {
              startDate: fromDate,
              endDate: toDate,
            },
          }
        );
      [res, err] = await executor.executeAsync(fetchData);
      if (err) {
        dispatch(
          openAlert({
            message:
              "Errore durante il recupero del Waterfall: " +
              err?.response?.headers?.apierror,
            type: "danger",
          })
        );
      }
    }

    let ret = [];

    const keys = [
      "processRuns",
      "automaticControls",
      "validationTypes",
      "dataProducts",
    ];

    keys.forEach((key) => {
      if (res?.data[key]) {
        ret.push(res.data[key]);
      }
    });

    console.log("getWaterfall res", ret);
    dispatch(getWaterfall(ret || []));
    return dispatch(stopLoading());
  };

export const attachmentDownload =
  (versionId, oid, name) => async (dispatch) => {
    console.log("attachmentDownload");
    dispatch(startLoading());
    let res = null;
    let err = null;

    if (USE_API) {
      const API_URI_CALL = `${API_URL}/api/v1/portal/versions/downloadVersionAttachment`;
      const fetchData = async () =>
        axiosInstance.get(API_URI_CALL, {
          responseType: "blob",
          params: {
            versionId: versionId,
            attachmentOid: oid,
          },
        });
      [res, err] = await executor.executeAsync(fetchData);
      if (err) {
        dispatch(
          openAlert({
            message:
              "Errore durante il download del file: " +
              err?.response?.headers?.apierror,
            type: "danger",
          })
        );
      }
    }

    const href = URL.createObjectURL(res.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", name); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);

    console.log("attachmentDownload res", res);
    dispatch(stopLoading());
  };

export const fetchOtherVersions = (versionId) => async (dispatch) => {
  console.debug("fetchOtherVersions ");
  dispatch(startLoading());
  dispatch(getOtherVersions([]));
  let res = null;
  let err = null;

  if (USE_API) {
    let API_URI_CALL = `${API_URL}/api/v1/portal/singleVersion/otherVersions/${versionId}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);
    [res, err] = await executor.executeAsync(fetchData);
    if (err) {
      dispatch(
        openAlert({
          message:
            "Errore durante il recupero delle altre versioni: " +
            err?.response?.headers?.apierror,
          type: "danger",
        })
      );
    }
  }

  console.debug("fetchOtherVersions res", res);
  dispatch(getOtherVersions(res?.data || []));
  return dispatch(stopLoading());
};

export const fetchCompareDimension =
  (versionId, otherVersionId) => async (dispatch) => {
    console.debug("fetchCompareVersions ");
    dispatch(startLoading());
    dispatch(getComparedVersions([]));
    let res = null;
    let err = null;

    if (USE_API) {
      let API_URI_CALL = `${API_URL}/api/v1/portal/singleVersion/compareDimensions`;
      const fetchData = async () =>
        axiosInstance.get(API_URI_CALL, {
          params: {
            versionIdLatest: versionId,
            versionIdPrevious: otherVersionId,
          },
        });
      [res, err] = await executor.executeAsync(fetchData);
      if (err) {
        dispatch(
          openAlert({
            message:
              "Errore durante il recupero del confronto: " +
              err?.response?.headers?.apierror,
            type: "danger",
          })
        );
      }
    }

    console.debug("fetchCompareVersions res", res);
    dispatch(getComparedVersions(res?.data || []));
    return dispatch(stopLoading());
  };

export const fetchKeys = (versionId) => async (dispatch) => {
  console.debug("fetchKeys ");
  dispatch(startLoading());
  dispatch(getKeys([]));
  let res = null;
  let err = null;

  if (USE_API) {
    let API_URI_CALL = `${API_URL}/api/v1/portal/singleVersion/keys/${versionId}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);
    [res, err] = await executor.executeAsync(fetchData);
    if (err) {
      dispatch(
        openAlert({
          message:
            "Errore durante il recupero delle chiavi: " +
            err?.response?.headers?.apierror,
          type: "danger",
        })
      );
    }
  }

  let ret = [];

  if (res?.data && res?.data.length > 0) {
    ret = res.data.map((item, index) => {
      return {
        id: index,
        name: item,
        obj: JSON.parse(item),
      };
    });
  }

  console.debug("fetchKeys res", res);
  dispatch(getKeys(ret));
  return dispatch(stopLoading());
};

export const fetchKeyChanges = (versionId, key) => async (dispatch) => {
  console.debug("fetchKeyChanges ");
  dispatch(startLoading());
  dispatch(getKeyChanges([]));
  let res = null;
  let err = null;

  if (USE_API) {
    let API_URI_CALL = `${API_URL}/api/v1/portal/singleVersion/keyChangeHistory`;
    const fetchData = async () =>
      axiosInstance.post(API_URI_CALL, key.name, {
        params: {
          versionId: versionId,
        },
        headers: {
          // Overwrite Axios's automatically set Content-Type
          "Content-Type": "application/json",
        },
      });
    [res, err] = await executor.executeAsync(fetchData);
    if (err) {
      dispatch(
        openAlert({
          message:
            "Errore durante il recupero delle chiavi: " +
            err?.response?.headers?.apierror,
          type: "danger",
        })
      );
    }
  }

  console.debug("fetchKeyChanges res", res);
  dispatch(getKeyChanges(res?.data || []));
  return dispatch(stopLoading());
};
export const submitTicket = (data) => async (dispatch) => {
  console.debug("submitTicket start");
  dispatch(startLoading());

  const currentuser = JSON.parse(localStorage.getItem("userInfo"));
  let decoded = {};

  if (currentuser) {
    decoded = jwtDdecode(currentuser.access_token);
  } else {
    decoded.name = "";
    decoded.email = "";
  }

  const formData = new FormData();

  formData.append("file", data.file);

  if (USE_API) {
    let API_URI_CALL = `${API_URL}/api/v1/portal/forMe/genericRequest`;

    // Wrap the axios call in a Promise
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(API_URI_CALL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          params: {
            templateCode: data.templateCode,
            utente: decoded.email,
            subject: data.subject,
            note: data.note,
          },
        })
        .then((response) => {
          if (response.data?.esito) {
            dispatch(
              openAlert({
                message:
                  "Ticket aperto con successo. N. Ticket : " +
                  response?.data?.ticketNum,
                type: "info",
              })
            );
            resolve(response.data);
          }
          // Resolve with the response data
          else throw response.data; //Force catch with error message
        })
        .catch((error) => {
          dispatch(
            openAlert({
              message: "Errore durante l'apertura del ticket: " + error.msgDesc,
              type: "danger",
            })
          );
          console.error("Submit Ticket error:", error);
          reject(error); // Reject with the error
        })
        .finally(() => {
          dispatch(stopLoading());
        });
    });
  }
};

export const exportPayload = (versionId) => async (dispatch, getState) => {
  console.debug("exportPayload start");

  if (getState().dataQualityReducer.downloadedFile) {
    dispatch(
      openAlert({
        message:
          "Attenzione, un processo di donwlod risulta ancora in corso, la preghiamo di aspettare.",
        type: "danger",
      })
    );
    return;
  }

  const versionInfo = getState().dataQualityReducer.versionInfo;

  console.log("VersionInfo", versionInfo);

  dispatch(
    openAlert({
      message: "File Richiesto, download in corso...",
      type: "info",
    })
  );

  dispatch(setFileDownloading(true));
  dispatch(
    setVersionDownloaded({
      versioneID: versionId,
      versioneProdotto: versionInfo?.product_name,
      versioneData: moment(versionInfo.reference_date).format("DD/MM/YYYY"),
    })
  );

  let res = null;
  let err = null;

  if (USE_API) {
    let API_URI_CALL = `${API_URL}/api/v1/portal/singleVersion/payloadExport/${versionId}`;
    const fetchData = async () =>
      axiosInstance.get(API_URI_CALL, {
        responseType: "blob",
      });
    [res, err] = await executor.executeAsync(fetchData);
    if (err) {
      dispatch(
        openAlert({
          message:
            "Errore durante l'esportazione del payload: " +
            err?.response?.headers?.apierror,
          type: "danger",
        })
      );
      return;
    }

    const fileUrl = URL.createObjectURL(res.data);

    dispatch(
      setDownloadedFile({
        url: fileUrl,
        fileName: res.headers.filename
          ? res.headers.filename
          : "gridExport.csv",
      })
    );
    console.log("exportPayload res", res);
  }
};

export const fetchDataProducts = () => async (dispatch) => {
  dispatch(startLoading());
  dispatch(getDataProducts([]));
  let res = null;
  let err = null;
  if (USE_API) {
    const API_URI_CALL = `${API_URL}/api/v1/portal/product/getProducts`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);
    [res, err] = await executor.executeAsync(fetchData);
    if (err) {
      dispatch(
        openAlert({
          message:
            "Errore durante il recupero dei prodotti: " +
            err?.response?.headers?.apierror,
          type: "danger",
        })
      );
    }
  }

  console.debug("fetchDataProducts res", res);

  dispatch(getDataProducts(res?.data || []));
  return dispatch(stopLoading());
}

export const fetchVersionLineage  = (versionId) => async (dispatch) => {
  dispatch(startLoading());
  dispatch(getVersionLineage([]));
  let res = null;
  let err = null;
  if (USE_API) {
    const API_URI_CALL = `${API_URL}/api/v1/portal/versions/versionLineage/${versionId}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);
    [res, err] = await executor.executeAsync(fetchData);
    if (err) {
      dispatch(
        openAlert({
          message:
            "Errore durante il recupero della lineage: " +
            err?.response?.headers?.apierror,
          type: "danger",
        })
      );
    }
  }

  console.debug("fetchVersionLineage res", res);
  dispatch(getVersionLineage(res?.data || []));
  return dispatch(stopLoading());
}

export const fetchDataLineage = (productTypeId) => async (dispatch) => {
  dispatch(startLoading());
  dispatch(getDataLineage([]));
  let res = null;
  let err = null;
  if (USE_API) {
    const API_URI_CALL = `${API_URL}/api/v1/portal/versions/dataLineage/${productTypeId}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);
    [res, err] = await executor.executeAsync(fetchData);
    if (err) {
      dispatch(
        openAlert({
          message:
            "Errore durante il recupero della lineage: " +
            err?.response?.headers?.apierror,
          type: "danger",
        })
      );
    }
  }

  console.debug("fetchDataLineage res", res);
  dispatch(getDataLineage(res?.data || []));
  return dispatch(stopLoading());
}


export default DataQualitySlice.reducer;
