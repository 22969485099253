import PropTypes from "prop-types"
import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { Container, Row, Col, Badge, Label, Form, FormGroup, Input, Button,} from "reactstrap";
import { setFilterIntervallo, setFilterDataDa,setFilterDataA, toogleDataCheck }  from "../../store/app/DataQualityStatus/DataQualitySlice";
import moment from "moment";


const FilterIntervallo = ( props ) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const refreshCount = useSelector(  (state) => state.dataQualityReducer.refreshCount )
	const filterIntervallo = useSelector(  (state) => state.dataQualityReducer.filterIntervallo )

	useEffect(() => {
		// get parameters from url query
		const valueNew = searchParams.get('interval') || filterIntervallo || props.defaultValue || 24
		dispatch (setFilterIntervallo (valueNew))

		if (valueNew >= 0){
			dispatch (setFilterDataDa( moment().subtract(valueNew, 'hours').format ('YYYY-MM-DD HH:mm:ss')))
			dispatch (setFilterDataA( moment().format ('YYYY-MM-DD HH:mm:ss')))
		}

		if(valueNew == -3){
			dispatch (setFilterDataDa( moment('2000-01-01').format ('YYYY-MM-DD 00:00:00')))
			dispatch (setFilterDataA( moment().format ('YYYY-MM-DD 23:59:59')))
		}

		
  }, [navigate, refreshCount]);


  const intervalloChanged = (e) => {
		// console.log ('FilterIntervallo::intervalloChanged', e)

		const value = e.target?.value || e;
		dispatch (setFilterIntervallo (value))




		if (value >= 0){
			dispatch (setFilterDataDa( moment().subtract(value, 'hours').format ('YYYY-MM-DD HH:mm:ss')))
			dispatch (setFilterDataA( moment().format ('YYYY-MM-DD HH:mm:ss')))

		}else { 
			dispatch (setFilterDataDa( moment().format ('YYYY-MM-DD 00:00:00')))
			dispatch (setFilterDataA( moment().format ('YYYY-MM-DD 23:59:59')))
		}

		if(value == -3){
			dispatch (setFilterDataDa( moment('2000-01-01').format ('YYYY-MM-DD 00:00:00'))
			)
			dispatch (setFilterDataA( moment().format ('YYYY-MM-DD 23:59:59'))
			)
		}

		dispatch (toogleDataCheck(true))
  };


	return(
		<Input 
			type="select" 
			name="intervallo" 
			{...props}
			onChange={ intervalloChanged }
			value = {filterIntervallo}
		 >
			{/* <option value="8">Ultime 8 ore</option> */}
			<option value="24">Ultime 24 ore</option>
			<option value="168">Ultima settimana</option>
			<option value="744">Ultimo mese</option>
			<option value="-2">Single 24H</option>
			<option value="-1">Range</option>
			<option value="-3">Tutto</option>
		</Input>
	)

}

FilterIntervallo.propTypes = {
  defaultValue: PropTypes.any
}

export default FilterIntervallo;