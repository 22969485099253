import { useEffect, useState } from "react";
import { process } from "@progress/kendo-data-query";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Label,
  Form,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap";
import classnames from "classnames";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { ColumnMenu } from "../components/kendoComponents/columnMenu/ColumnMenu";
import { ColumnProps } from "../components/kendoComponents/columnProps/ColumnProps";
import { GridPager } from "../components/kendoComponents/gridPager/GridPager";
import "../assets/scss/kendo-theme-custom.scss";
import "./processRuns.scss";
import FeatherIcon from "feather-icons-react";
import moment from "moment";

import {
  Chart,
  ChartTooltip,
  ChartXAxis,
  ChartXAxisItem,
  ChartYAxis,
  ChartYAxisItem,
  ChartSeries,
  ChartSeriesItem,
  ChartLegend,
} from "@progress/kendo-react-charts";
import "react-hammerjs";

import { SetCurrentActiveTab } from "../store/customizer/CustomizerSlice";

import LoaderSpin from "../components/loaderSpin/LoaderSpin";

import { useNavigate } from "react-router-dom";
import DomainsSelector from "../components/DataQuality/DomainsSelector";
import DataProductTreeSelector from "../components/DataQuality/DataProductTreeSelector";
import ProcessSelector from "../components/DataQuality/ProcessSelector";
import ExtendedStatsSelector from "../components/DataQuality/ExtendedStatsSelector";
import FilterIntervallo from "../components/DataQuality/FilterIntervallo";
import FilterDataDa from "../components/DataQuality/FilterDataDa";
import FilterDataA from "../components/DataQuality/FilterDataA";
import FilterSubmit from "../components/DataQuality/FilterSubmit";
import FilterOverlay from "../components/filterOverlay/FilterOverlay";
import { fetchProcessRunsTimeline } from "../store/app/ProcessRuns/ProcessRunsSlice";
import DateCell from "../components/kendoComponents/dateCell/DateCell";

const ProcessRuns = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const isSpinnerVisible = useSelector(
    (state) => state.dataQualityReducer.loading
  );
  const dataCheck = useSelector((state) => state.dataQualityReducer.dataCheck);
  const refreshCount = useSelector(
    (state) => state.dataQualityReducer.refreshCount
  );
  const processRunsList = useSelector(
    (state) => state.processRunsReducer.processRunsList || []
  );
  const defaultInterval = useSelector(
    (state) => state.dataQualityReducer.defaultInterval
  );
  const filterDataDa = useSelector(
    (state) => state.dataQualityReducer.filterDataDa
  );
  const filterDataA = useSelector(
    (state) => state.dataQualityReducer.filterDataA
  );
  const filterDomains = useSelector(
    (state) => state.dataQualityReducer.filterDomains
  );
  const filterDataProducts = useSelector(
    (state) => state.dataQualityReducer.filterDataProducts
  );
  const filterProcessTypes = useSelector(
    (state) => state.processRunsReducer.filterProcessTypes
  );
  const filterExtendedStatsDomains = useSelector(
    (state) => state.dataQualityReducer.filterExtendedStatsDomains
  );

  const [dataState, setDataState] = useState({
    sort: [
      {
        field: "code",
        dir: "asc",
      },
    ],
    skip: 0,
    take: 20,
  });

  const [dataResult, setDataResult] = useState(
    process(processRunsList, dataState)
  );

  const groupBy = (array, key) => {
    // Return the end result
    return array.reduce((result, currentValue) => {
      // If an array already present for key, push it to the array. Else create an array and push the object
      if (!result[currentValue[key]]) {
        result[currentValue[key]] = { key: currentValue[key], data: [] };
      }

      result[currentValue[key]].data.push(currentValue);
      // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
      return result;
    }, []);
  };

  const transform = (processRuns) => {
    let c = [];
    //Creazione Array Compatibile con KendoChart
    Object.keys(processRuns).forEach((status) => {
      c.push(processRuns[status]);
    });
    return c;
  };
  const onSeriesClick = (event) => {
    navigate(
      "/process-runs/detail/application-log/" +
        event.dataItem.version_id +
        "?" +
        searchParams
    );
    // console.log ('SERIES CLICK ', event)
  };

  const mapSeries = (item, idx) => (
    <ChartSeriesItem
      key={idx}
      type="scatter"
      data={item.data}
      name={item.key}
      xField="run_start_ts"
      yField="durationMs"
      color={item?.data[idx]?.info?.color}
      markers={{
        visible: true,
        type: "circle",
        size: 12,
        background: item?.data[idx]?.info?.color,
      }}
    />
  );

  const renderTooltip = (context) => {
    return (
      <div style={{ padding: 16 + "px" }}>
        Nome processo: {context?.point.dataItem.process_name}
        <br />
        Start time:{" "}
        {moment(context?.point.dataItem.run_start_ts).format(
          "DD/MM/Y - HH:mm:ss"
        )}{" "}
        <br />
        Durata: {context?.point.dataItem.duration}
        <br />
        Stato Esecuzione: {context?.point.dataItem.run_status}
        <br />
        Data Product: {context?.point.dataItem.product_name}
        <br />
        Versione: {context?.point.dataItem.version_id}
        <br />
        Stato Versione: {context?.point.dataItem.ext_status_name}
      </div>
    );
  };

  const diffDays = () => {
    const dateFormat = "YYYY-MM-DD HH:mm:ss";
    return moment(searchParams.get("toDate"), dateFormat).diff(
      moment(searchParams.get("fromDate"), dateFormat),
      "days"
    );
  };

  useEffect(() => {
    dispatch(
      fetchProcessRunsTimeline(
        filterDataDa,
        filterDataA,
        filterProcessTypes?.toString(),
        filterDomains,
        filterExtendedStatsDomains, 
        filterDataProducts
      )
    );

    return () => {
      dispatch(SetCurrentActiveTab("1"));
    };

  }, [navigate, refreshCount]);

  useEffect(() => {
    setDataResult(process(processRunsList, dataState));
  }, [processRunsList]);

  const currentActiveTab = useSelector(
    (state) => state.customizer.currentActiveTab
  );

  // Toggle active state for Tab
  const toggle = (tab) => {
    if (currentActiveTab !== tab) dispatch(SetCurrentActiveTab(tab));
  };

  return (
    <>
      <Form>
        <Row className="bg-light-primary px-4 pt-2">
          <Col>
            <Row className="g-3">
              <Col>
                <div className="mb-2">
                  <Label for="processRunsFilterIntervallo">Intervallo</Label>
                  <FilterIntervallo id="processRunsFilterIntervallo"
                    defaultValue={defaultInterval}
                    // className="form-dirty" Questa classe serve per dare l'aspetto di un filtro sporcato, non coerente con i risultati
                  />
                </div>
              </Col>
              <Col>
                <div className="mb-2">
                  <Label for="processRunsFilterDataDa">Data dal</Label>
                  <FilterDataDa id="processRunsFilterDataDa" name="DataDal" />
                </div>
              </Col>
              <Col>
                <div className="mb-2">
                  <Label for="processRunsFilterDataA">Al</Label>
                  <FilterDataA id="processRunsFilterDataA" name="DataAl" />
                </div>
              </Col>
              <Col>
                <div className="mb-2">
                  <Label for="processRunsDataProductTreeSelector">Data Products</Label>
                  <DataProductTreeSelector id="processRunsDataProductTreeSelector"/>
                </div>
              </Col>
              <Col>
                <div className="mb-2">
                  <Label for="processRunsProcessSelector">Tipo processo</Label>
                  <ProcessSelector id="processRunsProcessSelector"/>
                </div>
              </Col>
              <Col>
                <div className="mb-2">
                  <Label for="processRunsExtendedStatsSelector">Stato Versione</Label>
                  <ExtendedStatsSelector id="processRunsExtendedStatsSelector" />
                </div>
              </Col>

              <Col md="auto" className="pt-1">
                <FilterSubmit color="primary" className="mt-3" text="Filtra" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      <Container fluid className="py-2 px-4  position-relative ">
        <Row className="row g-3 mb-3">
          <Col>
            <Nav pills>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: currentActiveTab === "1",
                  })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  Process Run Timeline
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: currentActiveTab === "2",
                  })}
                  onClick={() => toggle("2")}
                >
                  Process Run List
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={currentActiveTab}>
              <TabPane
                className="grid_task_container_tabs border border-top-0"
                tabId="1"
              >
                <div className="py-2 px-3">
                  <p className="my-2 lh-sm">
                    Generato il {moment().format("D/M/Y")} alle{" "}
                    {moment().format("H:m:s")}. Processi Totali totali:{" "}
                    {processRunsList ? processRunsList?.length : 0}
                  </p>
                  <Chart
                    style={{
                      height: 460,
                    }}
                    transitions={false}
                    onSeriesClick={onSeriesClick}
                  >
                    {/* <ChartTitle text="Process Runs" /> */}
                    <ChartTooltip render={renderTooltip} />
                    <ChartSeries
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {transform(groupBy(processRunsList, "run_status"))?.map(
                        mapSeries
                      )}
                    </ChartSeries>
                    <ChartXAxis>
                      <ChartXAxisItem
                        title={{
                          text: "Start time",
                        }}
                        type="date"
                        baseUnit="days"
                        min={searchParams.get("fromDate")}
                        max={searchParams.get("toDate")}
                        majorUnit={diffDays() / 10}
                        minorUnit={diffDays()}
                        labels={{
                          format: "{0:dd/MM/yyyy}",
                        }}
                      />
                    </ChartXAxis>
                    <ChartYAxis>
                      <ChartYAxisItem
                        title={{
                          text: "Duration",
                        }}
                        labels={{
                          format: "{0}s",
                        }}
                      />
                    </ChartYAxis>
                    <ChartLegend
                      position="bottom"
                      visible={true}
                      offsetY={20}
                    />
                  </Chart>
                </div>
              </TabPane>

              <TabPane
                className="grid_task_container_tabs border border-top-0"
                tabId="2"
              >
                <Tooltip
                  openDelay={100}
                  position="right"
                  anchorElement="target"
                >
                  <div className="grid_task_container border">
                    <Grid
                      style={{ height: "100%" }}
                      pageable={true}
                      sortable={true}
                      total={dataResult?.length}
                      data={dataResult}
                      {...dataState}
                      groupable={true}
                      onDataStateChange={(e) => {
                        setDataState(e.dataState);
                        setDataResult(process(processRunsList, e.dataState));
                      }}
                      pager={(props) => GridPager(props, processRunsList)}
                    >
                      <Column
                        {...ColumnProps("run_id", dataState)}
                        title="Run"
                        width="120"
                        cell={(props) => (
                          <td>
                            <a
                              style={{ cursor: "pointer" }}
                              className="fw-bold text-decoration-underline"
                              onClick={(event) => {
                                navigate(
                                  "/process-runs/detail/application-log/" +
                                    props.dataItem.version_id +
                                    "?" +
                                    searchParams
                                );
                              }}
                              onKeyDown={() => console.log("onKeyDown")}
                            >
                              {props.dataItem.run_id}
                            </a>
                          </td>
                        )}
                        columnMenu={ColumnMenu}
                      />
                      <Column
                        {...ColumnProps("process_name", dataState)}
                        title="Processo"
                        cell={(props) => (
                          <td>
                            <span
                              title={
                                "Processo:" +
                                props.dataItem.process_type +
                                " Descrizione:" +
                                props.dataItem.process_desc +
                                " Validazione:" +
                                props.dataItem.is_manual_validation +
                                " Revisione:" +
                                props.dataItem.revision_num +
                                " Rilascio:" +
                                props.dataItem.release_date +
                                " Note:" +
                                props.dataItem.release_notes +
                                " Autore:" +
                                props.dataItem.release_author
                              }
                            >
                              {props.dataItem.process_name}
                            </span>
                          </td>
                        )}
                        columnMenu={ColumnMenu}
                      />
                      <Column
                        {...ColumnProps("", dataState)}
                        title="Stato esecuzione"
                        width="155"
                        cell={(props) => (
                          <td>
                            <span title={props.dataItem.run_exit_code}>
                              {props.dataItem.run_status}
                            </span>
                          </td>
                        )}
                        columnMenu={ColumnMenu}
                      />
                      <Column
                        {...ColumnProps("run_start_ts", dataState)}
                        title="Inizio"
                        width={200}
                        cell={(props) => DateCell(props, true)}
                        columnMenu={ColumnMenu}
                      />
                      <Column
                        {...ColumnProps("duration", dataState)}
                        title="Durata"
                        width="120"
                        columnMenu={ColumnMenu}
                      />
                      <Column
                        {...ColumnProps("version_id", dataState)}
                        title="Versione"
                        width="120"
                        cell={(props) => (
                          <td>
                            <a
                              style={{ cursor: "pointer" }}
                              className="fw-bold text-decoration-underline"
                              onClick={(event) => {
                                navigate(
                                  "/versioni/detail/" +
                                    props.dataItem.version_id +
                                    "?" +
                                    searchParams
                                );
                              }}
                              title={
                                "Dominio:" +
                                props.dataItem.domain_name +
                                " Riferimento:" +
                                props.dataItem.reference_date +
                                " Purpose:" +
                                props.dataItem.purpose_name +
                                " Status:" +
                                props.dataItem.status_name +
                                " Ext.Status:" +
                                props.dataItem.ext_status_name
                              }
                              onKeyDown={() => console.log("onKeyDown")}
                            >
                              {props.dataItem.version_id}
                            </a>
                          </td>
                        )}
                        columnMenu={ColumnMenu}
                      />
                      <Column
                        {...ColumnProps("product_name", dataState)}
                        title="Data Product"
                        cell={(props) => (
                          <td>
                            <a
                              style={{ cursor: "pointer" }}
                              className="fw-bold text-decoration-underline"
                              href={process.env.REACT_APP_ATLAN_PRE +   props.dataItem.product_type_handle  + process.env.REACT_APP_ATLAN_POST}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {props.dataItem.product_name}
                            </a>
                          </td>
                        )}
                        columnMenu={ColumnMenu}
                      />
                      <Column
                        {...ColumnProps("ext_status_name", dataState)}
                        title="Stato Versione"
                        columnMenu={ColumnMenu}
                      />
                    </Grid>
                  </div>
                </Tooltip>
              </TabPane>
            </TabContent>
          </Col>
        </Row>

        <LoaderSpin isVisible={isSpinnerVisible} />
        <FilterOverlay isVisible={dataCheck} />

        {/* <div className="filter_results_mask d-flex align-content-center flex-wrap justify-content-center">
          <div className=" p-4 text-light text-center">
            <p className="fs-3">
              Applica i filtri selezionati per aggiornare.</p>
            <p className="fs-3 mt-4">
              Oppure, resetta i filtri per riportarli allo stato coerente con i risultati mostrati.</p>
          </div>
        </div> 
        Questa sarebbe la maschera da sovrapporre ai risultati, credo che dovremmo trasformarla in un componente a cui passare un parametro acceso/spento in base alla coerenza tra filtri e risultati
        */}
      </Container>
    </>
  );
};

export default ProcessRuns;
