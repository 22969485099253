import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Label, Form, FormGroup } from "reactstrap";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { ColumnMenu } from "../components/kendoComponents/columnMenu/ColumnMenu";
import { GridPager } from "../components/kendoComponents/gridPager/GridPager";
import { ColumnProps } from "../components/kendoComponents/columnProps/ColumnProps";
import { useNavigate, useParams } from "react-router-dom";
import LoaderSpin from "../components/loaderSpin/LoaderSpin";

import FilterOverlay from "../components/filterOverlay/FilterOverlay";
import FilterIntervallo from "../components/DataQuality/FilterIntervallo";
import ProcessSelector from "../components/DataQuality/ProcessSelector";
import FilterSubmit from "../components/DataQuality/FilterSubmit";


import "../assets/scss/kendo-theme-custom.scss";
import { fetchLastProcessRuns  } from "../store/app/ProcessRuns/ProcessRunsSlice";
import DateCell from "../components/kendoComponents/dateCell/DateCell";

const LatestRuns = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { processId } = useParams();

  const refreshCount = useSelector(
    (state) => state.dataQualityReducer.refreshCount
  );
  const searchParams = new URLSearchParams(location.search);
  const lastProcessRunsList = useSelector((state) => state.processRunsReducer.lastProcessRunsList);
  const filterIntervallo = useSelector((state) => state.dataQualityReducer.filterIntervallo); 
  const dataCheck = useSelector((state) => state.dataQualityReducer.dataCheck);
  
  const isSpinnerVisible = useSelector(
    (state) => state.dataQualityReducer.loading
  );
  
  const [dataState, setDataState] = useState({
    sort: [
      {
        field: "code",
        dir: "asc",
      },
    ],
    skip: 0,
    take: 20,
  });

  const [dataResult, setDataResult] = useState(process(lastProcessRunsList, dataState));

  
 

  useEffect(() => {
    const processParam = searchParams.get("process");
    const processValue = processParam ? JSON.parse(processParam)[0] : "";
    dispatch(fetchLastProcessRuns(processId, processValue, filterIntervallo));   
  }, [refreshCount, navigate]);

  useEffect(() => {
    setDataResult(process(lastProcessRunsList, dataState));
  }, [lastProcessRunsList]);

  return (
    <>
       <Form>
        <Row className="bg-light-primary px-4 pt-2">
          <Col>
            <Row className="g-3">
              <Col>
                <FormGroup>
                  <Label for="latestRunsFilterIntervallo" id="latestRunsFilterIntervalloLabel">Intervallo</Label>
                  <FilterIntervallo id="latestRunsFilterIntervallo" />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="latestRunsProcessSelector" id="latestRunsProcessSelectorLabel">Tipo Processo</Label>
                  <ProcessSelector id="latestRunsProcessSelector"/>
                </FormGroup>
              </Col>        
              <Col md="auto" className="pt-1">
                <FilterSubmit id="latestRunsFilterSubmit" color="primary" className="mt-3" text="Filtra" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      <Container fluid className="py-2 px-4  position-relative">
        <Row>
          <Col>
            <div className="grid_task_container border">
              <Grid id="latestRunsGrid"
                style={{ height: "100%" }}
                pageable={true}
                sortable={true}
                total={dataResult?.length}
                data={dataResult}
                {...dataState}
                groupable={true}
                pager={(props) => GridPager(props, lastProcessRunsList)}
                onDataStateChange={(e) => {
                  setDataState(e.dataState);
                  setDataResult(process(lastProcessRunsList, e.dataState));
                }}
              >
                   <Column
                  {...ColumnProps("version_id", dataState)}
                  title="Versione"
                  columnMenu={ColumnMenu}
                  width={120}
                  cell={(props) => (
                    <td>
                      <a
                        style={{ cursor: "pointer" }}
                        className="fw-bold text-decoration-underline"
                        onClick={(event) => {
                          navigate(
                            "/versioni/detail/" +
                              props.dataItem.version_id +
                              "?" +
                              searchParams
                          );
                        }}
                        onKeyDown={() => console.log("onKeyDown")}
                      >
                        {props.dataItem.version_id}
                      </a>
                    </td>
                  )}
                />
                <Column
                  {...ColumnProps("run_status", dataState)}
                  title="Stato"
                  columnMenu={ColumnMenu}
                />
                <Column
                  {...ColumnProps("run_start_ts", dataState)}
                  title="Inizio Run"
                  columnMenu={ColumnMenu}
                  cell={(props) => DateCell(props, true)}
                />
                <Column
                  {...ColumnProps("run_end_ts", dataState)}
                  title="Fine Run"
                  columnMenu={ColumnMenu}
                  cell={(props) => DateCell(props, true)}
                />
                <Column
                  {...ColumnProps("release_notes", dataState)}
                  title="Note di rilascio"
                  columnMenu={ColumnMenu}
                />
                <Column
                  {...ColumnProps("duration", dataState)}
                  title="Durata"
                  columnMenu={ColumnMenu}
                />
              </Grid>
            </div>
          </Col>
        </Row>
        <LoaderSpin isVisible={isSpinnerVisible} />
        <FilterOverlay isVisible={dataCheck} />
      </Container>
    </>
  );
};

export default LatestRuns;
